<template>
  <div class="surveyPage">
    <carouselBanner paddingTop="14.60%" :bannerType="2"></carouselBanner>
    <div class="content_area clearfix">
      <div class="surveyNav fl">
        <div
            class="tc surveyNavItem"
            :class="{ active: currentPath == item.link }"
            v-for="(item, index) in navLinks"
            :key="index"
        >
          <router-link :to="item.link">{{ item.label }}</router-link>
        </div>
      </div>
      <div class="surveyChildPage fr">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import carouselBanner from '@/baseComponents/carouselBanner'

export default {
  components: {carouselBanner},
  data() {
    return {
      currentPath: "",
      navLinks: [
        {label: "协会简介", link: "/survey"},
        {label: "组织机构", link: "/survey/framework"},
        {label: "机构职能", link: "/survey/constitution"},
        {label: '协会章程', link: "/survey/structure"},
        // { label: "领导成员", link: "/survey/leader" },
        // { label: "理事单位", link: "/survey/director" },
        // { label: "监事单位", link: "/survey/supervisor" },
        // { label: "会员单位", link: "/survey/member" },
        // {label:'秘书处架构',link: "/survey/structure"},
        // { label: "大事记", link: "/survey/memorabilia" },
      ],
    };
  },
  mounted() {
    this.currentPath = this.$route.path;
  },
  methods: {
    handleActive(path) {
      if (path.indexOf("survey") != -1) {
        this.currentPath = path;
      }
    },
  },
  watch: {
    $route(nv) {
      this.handleActive(nv.path);
    },
  },
};
</script>

<style scoped lang="less">
.surveyPage {
  padding-bottom: 24px;

  .surveyNav {
    width: 110px;
    background: #ffffff;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.13);
    border-radius: 12px;
    padding: 5px 10px;
  }

  .surveyNavItem {
    width: 90px;
    height: 36px;
    line-height: 36px;
    margin: 10px auto;

    a {
      color: #666;
    }

    &.active, &:hover {
      background: rgba(227, 149, 79, .1);
      border-radius: 6px;

      a {
        color: #128144;
      }
    }
  }

  .surveyChildPage {
    width: 1066px;
  }
}
</style>